@import '/src/styles/base/Vars.scss';

/*******************************************************
	Padding
*******************************************************/

#headerContainer, section .sectionContainer {
    padding-right: 32px !important;
    padding-left: 32px !important;
}

section {
    padding: 16px 0;

    &:first-of-type {
        padding-top: 40px !important;
    } 
    
    &:last-of-type {
        padding-bottom: 40px !important;
    }

}

.react-calendar * {
    float: none;
}

@media only screen and (max-width: 600px){

    #headerContainer, section .sectionContainer {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }

}