
@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';

/*******************************************************
	Home
*******************************************************/

#mainHome {

    #btnCalendar {
        padding: 8px 16px;
        margin-bottom: 32px;
        border-radius: 12px;
        background-color: $color-theme-t;

        color: var(--color-txt-p);
        font-size: 16px;

        cursor: pointer;
        transition: 0.1s ease-in-out;

        &:hover {
            background-color: $color-theme-t-hover;
        }

    }

    #calendarContainer {
        position: fixed;
        bottom: 92px;
        z-index: 100;

        display: none;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        padding: 0 12px;
    
            &.open {
                display: flex;
            }

        #calendarContent {
            box-shadow: $color-box-shadow-p;
            
            overflow-x: auto;
        }

    }

    #listHome .listRowContainer {grid-template-columns: 50px 1fr repeat(7, 40px) 64px;}

    .react-calendar {
        border: none !important;
        border-radius: 12px 12px 0 0;
        background: var(--color-layout-s) !important;
        box-shadow: $color-box-shadow-p;

        transition: 0.1s ease-in-out;

        .react-calendar__navigation {
            height: 57px;
            border-radius: inherit;
            background: $color-theme-t !important;

            button {
                border-radius: 12px;
                color: $color-txt-p-dm !important;
                font-size: 20px;

                &:hover, &:focus {
                    background: var(--color-action-hover) !important;
                }

            }
            
        }

        .react-calendar__tile {
            color: var(--color-txt-p) !important;

            &:hover {
                background: var(--color-action-hover) !important;
            }

        }

        .react-calendar__tile--active {
            background: $color-theme-t !important;

            &:hover {
                background: $color-theme-t-hover !important;
            }

        }

        .react-calendar__tile--now {
            background: var(--color-layout-t) !important;
        }

        .react-calendar__month-view__days button {
            border-radius: 12px;
        }

        .react-calendar__month-view__weekdays {
            color: var(--color-txt-p) !important;
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            color: var(--color-txt-s) !important;
        }

    }

    @media only screen and (max-width: 800px){

        .boxGroup {
            grid-template-columns: repeat(2, 1fr) !important;
        }
        
    }
    
    @media only screen and (max-width: 600px){
    
        .boxGroup {
            grid-template-columns: 1fr !important;
        }
        
    }
    
}