@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';

/*******************************************************
	Section
*******************************************************/

section {

    .sectionContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sectionTitlesContainer {
            display: flex;
            flex-direction: column;
            gap: 4px 0;
            width: 100%;
            margin-bottom: 24px;

            .sectionTitleContent {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                width: 100%;

                h1, h2, h3 {
                    text-align: left;
                    word-wrap: break-word;
                }

                .sectionGoBackContainer {
                    display: flex;
                    align-items: center;
    
                    color: var(--color-txt-t);
                    font-size: 24px;
                    text-align: left;
    
                    cursor: pointer;
                    transition: 0.1s ease-in-out;
    
                    &:hover {
                        color: $color-theme-t-hover;
                    }
    
                    svg {margin-right: 8px;}
    
                    h3 {font-size: 16px;}
    
                }

                .sectionTitle {
                    color: var(--color-txt-p);
                    font-size: 40px;
                    font-weight: bold;
                }

                .sectionTitleComplement {
                    margin-left: 12px;

                    color: var(--color-txt-t);
                    font-size: 18px;
                    font-style: italic;
                }
        
                .sectionSubTitle {
                    color: var(--color-txt-t);
                    font-size: 20px;
                    font-style: italic;
                }

            }

        }

    }

}

@media only screen and (max-width: 600px){

    section {

        .sectionTitle {
            font-size: 28px !important;
        }

    }

}