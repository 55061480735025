@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';
@import '/src/styles/modules/themes/Status.scss';
@import '/src/styles/modules/themes/Colors.scss';

/*******************************************************
	Box
*******************************************************/

.boxGroup {
    display: grid;
    gap: 24px;
    width: 100%;

    .box {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: var(--color-layout-p);
        box-shadow: $color-box-shadow-s;
    
        transition: 0.1s ease-in-out;
    
        &.boxLink {
    
            &:hover {transform: translateY(-16px);}
    
        }
    
        &.status {
    
            .boxTopTitleContainer, .boxLinkLabelContainer a {
                background-color: var(--color-theme-status-s) !important;
    
                color: var(--color-theme-status-p) !important;
            }
    
            .boxLinkLabelContainer a:hover {
                color: var(--color-theme-status-t) !important;
            }
        
        }
        
        &.colors {
    
            .boxTopTitleContainer {
                background-color: var(--color-theme-colors-s) !important;
    
                color: var(--color-theme-colors-t) !important;
            }
    
        }
    
        a {
            display: grid;
            grid-template-rows: 1fr auto;
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    
        .boxTitle {
            margin: 12px 24px;
    
            color: var(--color-txt-p);
            font-size: 20px;
            font-weight: bold;
        }
    
        .boxTopTitleContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 12px 24px;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            background-color: var(--color-layout-s);
    
            color: var(--color-txt-t);
    
            transition: 0.1s ease-in-out;
    
            .boxTopTitle {
                color: inherit;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
            }
    
        }
    
        .boxContainer {
            width: 100%;
            border-radius: inherit;
    
            .boxSection {
                width: 100%;
                margin-bottom: 16px;
    
                &:last-of-type {margin: 0;}
    
            }
    
            .boxLinkLabelContainer {
                
                a {
                    padding: 8px 24px;
                    border-radius: 18px;
                    background-color: var(--color-layout-s);
    
                    color: var(--color-txt-t);
                    font-size: 16px;
                    font-weight: bold;
    
                    cursor: pointer;
                    transition: 0.1s ease-in-out;
                }
    
            }
    
            .boxImgContainer {
                position: relative;
    
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 300px;
                border-radius: 12px;
                background-color: var(--color-layout-s);
    
                transition: 0.1s ease-in-out;
    
                &:hover {transform: scale(1.04);}    

                img {
                    height: inherit;
                    object-fit: cover;
                    border-radius: inherit;
                }
    
            }
    
            .boxIconContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
    
                svg {
                    margin-right: 12px;
                    
                    color: var(--color-txt-s);
                }
    
                h4 {
                    color: var(--color-txt-s);
                    font-size: 24px;
                    font-weight: bold;
                    text-align: center;
                } 
    
            }
    
        }
    
    }

}