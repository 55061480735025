@import '/src/styles/modules/themes/Status.scss';

/*******************************************************
	Listas
*******************************************************/

#mainListas {

    .destinatarioBox {
        display: grid;
        grid-template-columns: 1fr 160px;
        gap: 0 16px;
        width: 100%;
        padding: 12px 24px;
        border-bottom: 2px solid var(--color-divider);

        transition: 0.1s ease-in-out;

        &:hover {background-color: var(--color-action-hover);}

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            transition: 0.1s ease-in-out;

            &:last-of-type {
                justify-content: flex-end;
            }

            h4, h5 {
                color: var(--color-theme-status-p);
                font-size: 16px;
                font-weight: bold;
            }

        }

    }

    #listListas .listRowContainer {grid-template-columns: 1fr 200px 60px;}

    @media only screen and (max-width: 800px){

        .boxGroup {
            grid-template-columns: repeat(2, 1fr) !important;
        }
        
    }
    
    @media only screen and (max-width: 600px){
    
        .boxGroup {
            grid-template-columns: 1fr !important;
        }
        
    }

}