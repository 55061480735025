@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';

/*******************************************************
	Page Render
*******************************************************/

#wrapper {
    min-height: 100vh;
    background-color: var(--color-layout-foreground);

    transition: 0.1s ease-in-out;
}