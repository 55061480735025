@import '/src/styles/base/Vars.scss';

/*******************************************************
	Light
*******************************************************/

.light-mode {
    --color-txt-p: #{$color-txt-p-lm};
    --color-txt-s: #{$color-txt-s-lm};
    --color-txt-t: #{$color-txt-t-lm};

    --color-layout-p: #{$color-layout-p-lm};
    --color-layout-s: #{$color-layout-s-lm};
    --color-layout-t: #{$color-layout-t-lm};
    --color-layout-foreground: #{$color-layout-foreground-lm};

    --color-action-active: #{$color-action-active-lm};
    --color-action-hover: #{$color-action-hover-lm};
    --color-action-selected: #{$color-action-selected-lm};

    --color-disabled-p: #{$color-disabled-p-lm};
    --color-disabled-s: #{$color-disabled-s-lm};

    --color-divider: #{$color-divider-lm};
}

/*******************************************************
	Dark
*******************************************************/

.dark-mode {
    --color-txt-p: #{$color-txt-p-dm};
    --color-txt-s: #{$color-txt-s-dm};
    --color-txt-t: #{$color-txt-t-dm};

    --color-layout-p: #{$color-layout-p-dm};
    --color-layout-s: #{$color-layout-s-dm};
    --color-layout-t: #{$color-layout-t-dm};
    --color-layout-foreground: #{$color-layout-foreground-dm};

    --color-action-active: #{$color-action-active-dm};
    --color-action-hover: #{$color-action-hover-dm};
    --color-action-selected: #{$color-action-selected-dm};

    --color-disabled-p: #{$color-disabled-p-dm};
    --color-disabled-s: #{$color-disabled-s-dm};

    --color-divider: #{$color-divider-dm};
}

.dark-mode {

    header {
        background-color: var(--color-layout-s) !important;
    }

    .listContainer .listSection.main, .listStatus {
        background-color: var(--color-layout-s) !important;
    }

    .box {
        background-color: var(--color-layout-s) !important;

        &.status {

            .boxTopTitleContainer, .boxLinkLabelContainer a {
                background-color: var(--color-theme-status-p) !important;
    
                color: var(--color-theme-status-s) !important;
            }
    
            .boxLinkLabelContainer a:hover {
                color: var(--color-txt-p) !important;
            }
        
        }
    
        .boxTopTitleContainer {
            background-color: var(--color-layout-t) !important;
        }
    
    }

}