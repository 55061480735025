@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';

/*******************************************************
	List
*******************************************************/

.listContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    border-radius: 12px 12px 0 0;
    background-color: var(--color-layout-p);
    
    overflow-x: auto;

    .listSection {
        width: auto;

        &.header {
            border-radius: inherit;
            background-color: $color-theme-t;

            .listRow .listRowContainer .listColumn {
                padding: 16px 0;
                
                color: inherit;
                font-size: 20px;

                &.icon svg {

                    &:hover {
                        color: $color-txt-s-dm;
                    }
                    

                }

            }

        }

        &.main {
            transition: 0.1s ease-in-out;

            .listRow {
                border-bottom: 2px solid var(--color-divider);

                color: var(--color-txt-t);

                &:hover {
                    background-color: var(--color-action-hover);

                    color: var(--color-txt-p) !important;
                }

                .listRowContainer {

                    &.status {
                        color: var(--color-theme-status-p);
                    }

                    .listColumn {
                        color: inherit;
                        font-size: 16px;

                        &.status {
                            color: var(--color-theme-status-p) !important;
                        }
                        
                    }

                }

            }

        }

        a {width: auto;}

        .listRow {
            width: 100%;

            transition: 0.1s ease-in-out;

            .listRowContainer {
                display: grid;
                column-gap: 12px;
                
                width: 100%;
                padding: 0 24px;

                .listColumn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    padding: 12px 0;

                }

            }

        }
        
        .listStatus {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 48px 24px;
            border-radius: 0 0 12px 12px;
            background-color: var(--color-layout-p);
        }

    }

}