@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';

/*******************************************************
	Header
*******************************************************/

header {
	position: fixed;
	top: 0;

	background-color: var(--color-layout-p);
	box-shadow: 0 4px 6px 0 rgb(0,0,0,0.2);

    z-index: 2;
    transition: 0.1s ease-in-out;

    nav {
        width: 100%;

        #headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 72px;

            .headerContent {
                height: 100%;

                &#headerBanner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
    
                    #headerBannerText {
                        color: var(--color-txt-p);
                        font-size: 32px;
                        font-weight: bold;

                        transition: 0.1s ease-in-out;
                    }
                
                    #headerBannerTextComplement {
                        margin-left: 16px;
                
                        color: var(--color-txt-t);
                        font-size: 16px;
                        font-style: italic;

                        transition: 0.1s ease-in-out;
                    }
    
                }

                &#headerBoxGroup {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
    
                    .headerBox {
                        display: flex;
                        align-items: center;
                        justify-content: center; 
                        width: 58px;
                        height: 58px;
                        padding: 12px;
                
                        cursor: pointer;
                        transition: 0.1s ease-in-out;

                        &:nth-child(1), &:nth-child(2), &:nth-child(3){
                            pointer-events: none;
                        }
                
                        &:nth-child(1){
                            background-color: $color-theme-p;
    
                            &:hover {
                                background-color: $color-theme-p-hover;
                            }
    
                        }
                
                        &:nth-child(2){
                            background-color: $color-theme-s;
    
                            &:hover {
                                background-color: $color-theme-s-hover;
                            }
    
                        }   
                
                        &:nth-child(3){
                            background-color: $color-theme-t;
    
                            &:hover {
                                background-color: $color-theme-t-hover;
                            }
    
                        }

                        &:nth-child(4){
                            color: var(--color-txt-t);
    
                            &:hover {
                            background-color: var(--color-action-hover);

                                color: var(--color-txt-p);
                            }

                            svg {color: inherit !important;}
    
                        }
    
                        svg {
                            color: $color-txt-p-lm;
                            font-size: 24px;
                        }
    
                    }      
    
                }

            }
            
        }

    }

}

@media only screen and (max-width: 600px){

    header {

        #headerBoxGroup {
            grid-template-columns: 1fr !important;

            .headerBox {

                &:nth-child(1), &:nth-child(2), &:nth-child(3){
                    display: none !important;
                }

            }

        }

    }

}