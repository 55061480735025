@import '/src/styles/base/Vars.scss';
@import '/src/styles/modules/themes/Theme.scss';
@import '/src/styles/modules/themes/Status.scss';

/*******************************************************
	Destinatário
*******************************************************/

#mainDestinatario {

    .status svg {
        color: var(--color-theme-status-p) !important;
    }
    
    .destinatarioInfoGroup {
        display: grid;
        gap: 24px;
        width: 100%;
    
        .destinatarioInfoContainer {
            position: relative;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 0 24px;
            width: 100%;
        
            /*&:hover::after {
                position: absolute;
                top: -8px;
                right: -8px;
                bottom: -8px;
                left: -8px;
        
                content: '';
                border-radius: 12px;
                background-color: rgba(0,0,0,0.2);
            }*/
        
            .destinatarioInfoContent {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
    
                svg {color: var(--color-txt-p);}
        
                h3 {
                    color: var(--color-txt-p);
                    font-size: 18px;
                    font-weight: bold;
                }
        
                h4 {
                    color: var(--color-txt-t);
                    font-size: 16px;
                }
        
            }
        
        }
    
    }

    @media only screen and (max-width: 800px){

        .destinatarioInfoGroup {
            grid-template-columns: repeat(2, 1fr) !important;
        }

        #sectionNotas .boxGroup {
            grid-template-columns: 1fr !important;
        }
        
    }

    @media only screen and (max-width: 600px){

        .destinatarioInfoGroup {
            grid-template-columns: 1fr !important;
        }

    }

}